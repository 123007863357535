import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TileLayer, Marker, Polyline } from "react-leaflet";

import L from "leaflet";
import type { LatLngTuple } from "leaflet";

import { useModal } from "@/context/modal/modal";
import { useSessionDetailsContext } from "@/context/session-details/session-details";

import { Title } from "@/pages/session-detail/session-detail.styles";

import ContentLoader from "@/components/content-loader/content-loader";
import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import { ExpandDiagonalIcon24 } from "@/components/icons";
import Tooltip from "@/components/ui/tooltip/tooltip";

import type { MovementSegment } from "@/types/session-details";

import MapEffect from "./components/map-effect";
import MapObserver from "./components/map-observer";
import SpeedControl from "./components/speed-control";

import { useMapDataTimeline } from "./hooks/useMapDataTimeline";

import {
    ExpandIconContainer,
    HeaderContainer,
    MapAndControlsWrapper,
    MapWrapper,
    MapWithControlsWrapper,
    StyledMapContainer,
    StyledControls,
    TitleAndTooltipWrapper,
} from "./map-with-controls.styles";

//Currently on the free plan but once this is paid the access token and URL should come from BE.
const accessToken =
    "pk.eyJ1IjoibGF1cmF2cmFpIiwiYSI6ImNsdm1td3pyczAzdHQyaW0wenh1amtmdWwifQ.RGG_jsrFUrOGYrj0jz7S0g";
const mapboxURL = `https://api.mapbox.com/styles/v1/lauravrai/clvnwqljf00cz01qu7q6lhfk0/tiles/256/{z}/{x}/{y}@2x?access_token=${accessToken}`;

const speedBlockColors = ["#8D2260", "#641844", "#4D4D4D", "#A9501C", "#EE7127"];

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Define map settings and constants in a config object
const mapConfig = {
    zoom: undefined as number | undefined,
    minZoom: 5,
    maxZoom: 18,
    maxBounds: [
        [35, -25],
        [70, 40],
    ] as [LatLngTuple, LatLngTuple],
    radius: 5,
    polylineOuterWeight: 12,
    polylineInnerWeight: 10,
    circleFillColor: {
        start: "#81842C",
        end: "#C13D40",
        observation: "#0B5370",
    },
    circleColor: "#f2f2f2",
    circleFillOpacity: 1,
};

const rotationThreshold = 5;
const averageSpeedWindow = 6;

const makeCoords = (_movementData: any): LatLngTuple => {
    return [parseFloat(_movementData.latitude), parseFloat(_movementData.longitude)] as LatLngTuple;
};

interface MapWithControlsProps {
    isInModal: boolean;
    handleExpandContainerButtonClick: () => void;
    mapData: any;
    isLoading: boolean;
    isError: boolean;
}

const MapWithControls = ({
    isInModal,
    handleExpandContainerButtonClick,
    mapData,
    isLoading,
    isError,
}: MapWithControlsProps) => {
    const { isModalOpen } = useModal();
    const { playMapTimeline, pauseMapTimeline, isPlayingMapTimeline, currentTick } =
        useSessionDetailsContext();

    const {
        setUpdatedTick,
        durationTimecode,
        currentTimecode,
        totalTickCount,
        currentObservations,
    } = useMapDataTimeline({
        _chartData: mapData?.movementData || [],
    });

    const [minCoords, setMinCoords] = useState<LatLngTuple | null>(null);
    const [maxCoords, setMaxCoords] = useState<LatLngTuple | null>(null);
    const [movementData, setMovementData] = useState<MovementSegment[]>([]);
    const [isUsingSpeed, setIsUsingSpeed] = useState<boolean>(true);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const positionMarkerRef = useRef<L.Marker | any>(null);
    const previousHeadingRef = useRef<number>(0); // Store previous heading to check for significant movement
    const [rotationAngle, setRotationAngle] = useState<number>(0);
    const [isPristine, setIsPristine] = useState(true);

    const hasData = movementData?.length > 0 && !isError;
    const isEmptyState = !hasData && !isError && !isModalOpen;
    const isErrorState = isError && !hasData;
    const isModalContentLoading = isModalOpen && !hasData && !isError;

    // Define a function to create a custom icon
    const createCustomIcon = (iconUrl: string, size = 25) =>
        new L.Icon({
            iconUrl: iconUrl,
            iconSize: [size, size], // Size of the icon
            iconAnchor: [size / 2, size / 2], // Anchor point of the icon
            popupAnchor: [0, -15], // Where the popup should open relative to the iconAnchor
            rotationAngle: rotationAngle,
        });

    // Use the function to create icons
    const customStartMarkerIcon = createCustomIcon("/assets/start-marker.svg", 30);
    const customEndMarkerIcon = createCustomIcon("/assets/end-marker.svg", 30);
    const customCurrentMarkerIcon = new L.DivIcon({
        className: "rotating-icon",
        html: `<div style="width: 60px; height: 60px;">
       <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    style="transform-origin: 50% 50%; transform: rotate(${rotationAngle}deg); width: 60px; height: 60px;"
>
<path d="M16 7L25 26L16 20L7 26L16 7Z" fill="#0B5370" stroke="white" stroke-width="0.64"/>
</svg>

      </div>`,
        iconSize: [260, 260], // Size of the icon
        iconAnchor: [30, 30], // Anchor point of the icon
        popupAnchor: [0, -15], // Where the popup should open relative to the iconAnchor
    });

    //Update the style of the current DOM icon ensuring the anchor
    //is central and keeping the transform3d intact while adding a rotational value.
    const updateMarkerHeading = (newHeading: number) => {
        const headingDifference = Math.abs(newHeading - previousHeadingRef.current);

        if (headingDifference >= rotationThreshold) {
            previousHeadingRef.current = newHeading;

            if (positionMarkerRef.current) {
                setRotationAngle(newHeading);
            }
        }
    };

    useEffect(() => {
        if (currentTick > 0) {
            setIsPristine(false);
        }

        // Re-center the map when currentTick changes
        if (currentObservations?.default) {
            setCurrentCenter({
                coords: currentObservations.default.currentPosition,
                zoom: mapConfig.zoom,
            });
            updateMarkerHeading(currentObservations.default.currentHeading);
        }
    }, [currentTick, currentObservations, mapData]);

    // Modify getColorFromSpeed to calculate average speed from current and previous N speeds
    const getColorFromSpeed = (
        speeds: number[],
        currentIndex: number,
        minSpeed: number,
        maxSpeed: number,
    ) => {
        let sum = 0;
        let count = 0;

        for (let i = Math.max(0, currentIndex - averageSpeedWindow); i <= currentIndex; i++) {
            sum += speeds[i];
            count++;
        }

        const averageSpeed = sum / count;
        const speedRange = maxSpeed - minSpeed;
        const speedIndex = Math.floor(
            ((averageSpeed - minSpeed) / speedRange) * (speedBlockColors.length - 1),
        );

        //Only return the speedblock color if speed is being used.
        //Otherwise return the last index colour of speedBlock.

        return speedBlockColors[isUsingSpeed ? speedIndex : speedBlockColors.length - 1];
    };

    const [currentCenter, setCurrentCenter] = useState<{
        coords: LatLngTuple;
        zoom?: number;
    } | null>(null);

    useEffect(() => {
        if (minCoords && maxCoords && wrapperRef.current) {
            const isValidCoord = (coord: LatLngTuple) =>
                !isNaN(coord[0]) && !isNaN(coord[1]) && isFinite(coord[0]) && isFinite(coord[1]);

            if (isValidCoord(minCoords) && isValidCoord(maxCoords)) {
                const centerLat = (minCoords[0] + maxCoords[0]) / 2;
                const centerLong = (minCoords[1] + maxCoords[1]) / 2;
                const bounds = L.latLngBounds(
                    L.latLng(minCoords[0], minCoords[1]),
                    L.latLng(maxCoords[0], maxCoords[1]),
                );
                const map = L.map(wrapperRef.current).fitBounds(bounds);
                const zoomLevel = map.getZoom();
                map.remove();

                if (zoomLevel <= 0) {
                    setTimeout(() => setMinCoords([...minCoords]), 100);
                } else {
                    setCurrentCenter({
                        coords: [centerLat, centerLong],
                        zoom: zoomLevel,
                    });

                    mapConfig.zoom = zoomLevel;
                }
            }
        }
    }, [minCoords, maxCoords]); // Ensure this effect runs whenever minCoords or maxCoords changes

    useEffect(() => {
        if (mapData?.movementData) {
            const latitudes = mapData.movementData.map((item: any) => parseFloat(item.latitude));
            const longitudes = mapData.movementData.map((item: any) => parseFloat(item.longitude));

            const minLat = Math.min(...latitudes);
            const maxLat = Math.max(...latitudes);
            const minLong = Math.min(...longitudes);
            const maxLong = Math.max(...longitudes);

            setMinCoords([minLat, minLong]);
            setMaxCoords([maxLat, maxLong]);
        }
    }, [mapData]); // Ensure this effect runs whenever mapData changes

    useEffect(() => {
        const speeds = mapData.movementData.map((item: { speed: string }) =>
            parseFloat(item.speed),
        );

        for (const item of mapData.movementData) {
            if (item.speed === "null") {
                setIsUsingSpeed(false);
                break;
            } else {
                setIsUsingSpeed(true);
            }
        }

        // Calculate average speeds for each point considering the last N speeds
        const averageSpeeds = speeds.map((_: any, index: number) => {
            let sum = 0;
            let count = 0;

            for (let i = Math.max(0, index - averageSpeedWindow); i <= index; i++) {
                sum += speeds[i];
                count++;
            }

            return sum / count;
        });

        const minSpeed = Math.min(...averageSpeeds);
        const maxSpeed = Math.max(...averageSpeeds);

        const processedData = mapData?.movementData?.reduce(
            (acc: any, item: any, index: any, array: any) => {
                if (index === 0) return acc;
                const prevItem = array[index - 1];
                const color = getColorFromSpeed(averageSpeeds, index, minSpeed, maxSpeed);
                acc.push({
                    coords: [
                        [parseFloat(prevItem.latitude), parseFloat(prevItem.longitude)],
                        [parseFloat(item.latitude), parseFloat(item.longitude)],
                    ],
                    color: color,
                    speed: speeds[index],
                    heading: item.heading,
                });

                return acc;
            },
            [],
        );
        setMovementData(processedData);
    }, [mapData, averageSpeedWindow, isUsingSpeed]);

    const handleDoubleClick = (coords: LatLngTuple) => {
        return {
            dblclick: () => setCurrentCenter({ coords, zoom: mapConfig.zoom }),
        };
    };

    const startingPoint: any = mapData?.movementData?.length
        ? makeCoords(mapData?.movementData[0])
        : null;
    const endingPoint: any = mapData?.movementData?.length
        ? makeCoords(mapData?.movementData.at(-1))
        : null;

    return (
        <MapWithControlsWrapper>
            <HeaderContainer>
                <TitleAndTooltipWrapper>
                    <Title>
                        <FormattedMessage id={"session-detail:route"} />
                    </Title>
                    <Tooltip formattedMessage="session-detail:route-map:tooltip" />
                </TitleAndTooltipWrapper>

                {!isModalOpen && hasData && !isError && (
                    <ExpandIconContainer onClick={handleExpandContainerButtonClick}>
                        <ExpandDiagonalIcon24 />
                    </ExpandIconContainer>
                )}
            </HeaderContainer>

            {hasData && (
                <MapAndControlsWrapper>
                    <MapWrapper renderedInModal={isInModal} ref={wrapperRef}>
                        <StyledMapContainer
                            center={startingPoint || [0, 0]}
                            zoom={mapConfig.zoom}
                            minZoom={mapConfig.minZoom}
                            maxZoom={mapConfig.maxZoom}
                            maxBounds={mapConfig.maxBounds}
                        >
                            <TileLayer url={mapboxURL} accessToken={accessToken} />
                            {movementData.map((segment, index) => (
                                <Polyline
                                    key={`segment-${index}`}
                                    positions={segment.coords}
                                    color={"#fff"}
                                    weight={mapConfig.polylineOuterWeight}
                                />
                            ))}
                            {movementData.map((segment, index) => (
                                <Polyline
                                    key={`segment-outer-${index}`}
                                    positions={segment.coords}
                                    color={segment.color}
                                    weight={mapConfig.polylineInnerWeight}
                                />
                            ))}
                            {startingPoint && (
                                <Marker
                                    position={startingPoint}
                                    icon={customStartMarkerIcon}
                                    eventHandlers={handleDoubleClick(startingPoint)}
                                />
                            )}
                            {endingPoint && (
                                <Marker
                                    position={endingPoint}
                                    icon={customEndMarkerIcon}
                                    eventHandlers={handleDoubleClick(endingPoint)}
                                />
                            )}
                            {!isPristine && currentObservations?.default && (
                                <Marker
                                    position={currentObservations.default.currentPosition}
                                    icon={customCurrentMarkerIcon}
                                    eventHandlers={handleDoubleClick(
                                        currentObservations.default.currentPosition,
                                    )}
                                    ref={positionMarkerRef}
                                />
                            )}
                            {mapData && currentCenter?.coords && (
                                <MapEffect
                                    centerPosition={{
                                        coords: new L.LatLng(
                                            currentCenter.coords[0],
                                            currentCenter.coords[1],
                                        ),
                                        zoom: currentCenter.zoom,
                                    }}
                                    play={playMapTimeline}
                                    pause={pauseMapTimeline}
                                    isPlaying={isPlayingMapTimeline}
                                />
                            )}
                            <MapObserver />
                        </StyledMapContainer>
                    </MapWrapper>

                    {movementData?.length > 0 && (
                        <>
                            {!isModalOpen && <SpeedControl displaySpeed={isUsingSpeed} />}
                            <StyledControls
                                isDisabled={isLoading || !mapData}
                                isPlaying={isPlayingMapTimeline}
                                play={playMapTimeline}
                                pause={pauseMapTimeline}
                                currentTick={currentTick}
                                setTick={setUpdatedTick}
                                totalTickCount={totalTickCount}
                                currentTime={currentTimecode}
                                duration={durationTimecode}
                                isInModal={isModalOpen}
                            />
                        </>
                    )}
                </MapAndControlsWrapper>
            )}

            {isModalContentLoading && <ContentLoader height="100%" />}

            {isEmptyState && (
                <EmptyState
                    title={"session-detail:route-map:empty-state:title"}
                    emptyStateType={EmptyStateType.Charts}
                    minHeight="22.3125rem"
                />
            )}

            {isErrorState && (
                <EmptyState
                    title="error-state:title"
                    description="error-state:description"
                    emptyStateType={EmptyStateType.Error}
                    minHeight="22.3125rem"
                />
            )}
        </MapWithControlsWrapper>
    );
};

export default MapWithControls;
