import { useState, useEffect, useMemo, useRef } from "react";

import { useSessionDetailsContext } from "@/context/session-details/session-details";

import { calculateTime, flattenObservations, getMinMaxDateTime } from "../utils/utils";

interface UseMapDataTimelineProps {
    _chartData: any;
    tickDurationMs?: number;
}

// Custom hook to manage flight progress state
export const useMapDataTimeline = ({
    _chartData,
    tickDurationMs = 45,
}: UseMapDataTimelineProps) => {
    const { isPlayingMapTimeline, currentTick, setTick } = useSessionDetailsContext();

    const tickRef = useRef(currentTick);
    const [updatedTick, setUpdatedTick] = useState(currentTick);
    const updatedTickRef = useRef(currentTick);
    const isPlayingRef = useRef<boolean>(isPlayingMapTimeline);
    const [currentObservations, setCurrentObservations] = useState<any>({}); // Current observations state
    const animationFrameIdRef = useRef<number | null>(null);

    // Memoize chartData for optimization
    const chartData: any = useMemo(
        () => ({
            default: _chartData,
        }),
        [_chartData],
    );

    // Flatten chart data for easier access
    const flattenedChartData: any = useMemo(() => {
        return Object.keys(chartData).reduce((acc: any, instrument) => {
            acc[instrument] = flattenObservations(chartData[instrument]);

            return acc;
        }, {});
    }, [chartData]);

    // Calculate min and max DateTime from all instruments
    const dateTimeRange = useMemo(() => {
        const minDateTime = getMinMaxDateTime(flattenedChartData, true);
        const maxDateTime = getMinMaxDateTime(flattenedChartData, false);

        return {
            minDateTime: minDateTime?.toISOString(),
            maxDateTime: maxDateTime?.toISOString(),
        };
    }, [flattenedChartData]);

    // Calculate current DateTime based on the tick index
    const currentDateTime = useMemo(() => {
        if (!dateTimeRange.minDateTime) {
            return null;
        }

        const startDateTime = new Date(dateTimeRange.minDateTime);
        const currentDateTime = new Date(startDateTime.getTime() + currentTick * tickDurationMs);

        return currentDateTime;
    }, [dateTimeRange, currentTick, tickDurationMs]);

    // Calculate total tick count based on the time range
    const totalTickCount = useMemo(() => {
        if (!dateTimeRange.minDateTime || !dateTimeRange.maxDateTime) {
            return 0;
        }

        const minDateTime = new Date(dateTimeRange.minDateTime);
        const maxDateTime = new Date(dateTimeRange.maxDateTime);
        const differenceInMilliseconds = maxDateTime.getTime() - minDateTime.getTime();
        const differenceInTicks = Math.ceil(differenceInMilliseconds / tickDurationMs);

        return differenceInTicks;
    }, [dateTimeRange]);

    // Calculate duration and current timecodes
    const durationTimecode = useMemo(
        () => calculateTime(totalTickCount - 1, tickDurationMs),
        [totalTickCount, tickDurationMs],
    );
    const currentTimecode = useMemo(
        () => calculateTime(currentTick, tickDurationMs),
        [currentTick, tickDurationMs],
    );

    // Update current observations based on the current DateTime
    //with an object of current position array [lat, long] and current heading value.
    useEffect(() => {
        if (!currentDateTime) {
            setCurrentObservations({});

            return;
        }

        const newObservations = Object.keys(flattenedChartData).reduce((acc: any, instrument) => {
            const currentDateTimeFloor = Math.floor(currentDateTime.getTime() / 1000) * 1000;
            const currentDateTimeCeil = Math.ceil(currentDateTime.getTime() / 1000) * 1000;
            const instrumentDataFrom =
                flattenedChartData[instrument][new Date(currentDateTimeFloor).toISOString()];
            const instrumentDataTo =
                flattenedChartData[instrument][new Date(currentDateTimeCeil).toISOString()];

            if (instrumentDataFrom && instrumentDataTo) {
                const ratio = (currentDateTime.getTime() - currentDateTimeFloor) / 1000;
                const interpolatedDataLatitude =
                    parseFloat(instrumentDataFrom.latitude) +
                    ratio *
                        (parseFloat(instrumentDataTo.latitude) -
                            parseFloat(instrumentDataFrom.latitude));
                const interpolatedDataLongitude =
                    parseFloat(instrumentDataFrom.longitude) +
                    ratio *
                        (parseFloat(instrumentDataTo.longitude) -
                            parseFloat(instrumentDataFrom.longitude));

                const interpolatedDataHeading =
                    parseFloat(instrumentDataFrom.heading) +
                    ratio *
                        (parseFloat(instrumentDataTo.heading) -
                            parseFloat(instrumentDataFrom.heading));

                const normalizedDataHeading = normalizeRotation(interpolatedDataHeading);

                // Validate latitude and longitude
                if (
                    !isFinite(interpolatedDataLatitude) ||
                    Math.abs(interpolatedDataLatitude) > 90 ||
                    !isFinite(interpolatedDataLongitude) ||
                    Math.abs(interpolatedDataLongitude) > 180 ||
                    !isFinite(interpolatedDataHeading)
                ) {
                    // eslint-disable-next-line no-console
                    console.error(
                        `Invalid LatLng values: (${interpolatedDataLatitude}, ${interpolatedDataLongitude}, ${interpolatedDataHeading})`,
                    );
                } else {
                    acc[instrument] = {
                        currentPosition: [interpolatedDataLatitude, interpolatedDataLongitude],
                        currentHeading: normalizedDataHeading,
                    };
                }
            } else {
                acc[instrument] = null;
            }

            return acc;
        }, {});
        setCurrentObservations(newObservations); // Set the current observations state
    }, [currentDateTime, flattenedChartData]);

    // This function will ensure the rotation value stays within the bounds of a 360
    // degree rotational range and remove any negative value.
    const normalizeRotation = (rotation: number) => {
        if (rotation % 360 < 0) {
            rotation += 360;
        }

        return rotation;
    };

    //update the tick interval
    const updateTick = () => {
        if (!isPlayingRef.current) {
            if (animationFrameIdRef.current) {
                cancelAnimationFrame(animationFrameIdRef.current);
                animationFrameIdRef.current = null;
            }

            return; // Stop further frames from being requested
        }

        if (isPlayingRef.current) {
            tickRef.current += 1;

            if (tickRef.current >= totalTickCount) {
                cancelAnimationFrame(animationFrameIdRef.current!);
                animationFrameIdRef.current = null;

                return;
            }

            //TODO: This needs different behavior, currently giving a max depth error in development.
            setTick(tickRef.current);

            //next frame request
            animationFrameIdRef.current = requestAnimationFrame(updateTick);
        }
    };

    //Update the isPlaying reference in order to use inside a requested animation frame.
    useEffect(() => {
        isPlayingRef.current = isPlayingMapTimeline;
    }, [isPlayingMapTimeline]);

    // Effect to handle the playback tick updates
    useEffect(() => {
        //if the play bar is skipped we need an immediate update.
        if (updatedTickRef.current !== updatedTick) {
            tickRef.current = updatedTick;
            updatedTickRef.current = updatedTick;
            animationFrameIdRef.current = null;
            animationFrameIdRef.current = requestAnimationFrame(updateTick);
            //setTick(tickRef.current);
        }

        //Otherwise stick to requesting animation frames for performant functionality
        if (!animationFrameIdRef.current) {
            animationFrameIdRef.current = requestAnimationFrame(updateTick);
        } else if (!isPlayingMapTimeline && animationFrameIdRef.current) {
            cancelAnimationFrame(animationFrameIdRef.current);
            animationFrameIdRef.current = null;
        }
    }, [isPlayingMapTimeline, totalTickCount, tickDurationMs, updatedTick]);

    // Return the hook's state and control methods
    return {
        setUpdatedTick,
        currentObservations,
        durationTimecode,
        currentTimecode,
        totalTickCount,
    };
};

export default useMapDataTimeline;
