import styled from "styled-components";

import base from "@/themes/base/base";

import type { StylingProps } from "./modal-with-context.types";

export const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(18, 18, 18, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

export const ModalContent = styled.div<StylingProps>`
    font-family: "Roboto";
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    color: ${base.colors.neutral.grey[50]};
    background-color: ${({ backgroundColor }) =>
        backgroundColor ? `${backgroundColor}` : `${base.colors.neutral.grey[800]}`};
    padding: ${({ padding }) => (padding ? `${padding}` : "1.5rem")};
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}` : "0.5rem")};
    box-shadow: ${({ boxShadow }) =>
        boxShadow ? `${boxShadow}` : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"};

    width: ${({ width, minWidth }) => !minWidth && width && `${width}`};
    height: ${({ height, minHeight }) => !minHeight && height && `${height}`};
    min-width: ${({ minWidth }) => minWidth && `${minWidth}`};
    min-height: ${({ minHeight }) => minHeight && `${minHeight}`};
`;

export const CloseContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const CloseIconContainer = styled.div`
    display: flex;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
        background-color: ${base.colors.neutral.grey[500]};
    }

    &:active {
        border: 1px solid ${base.colors.primary.orange[300]};
        background-color: ${base.colors.neutral.grey[500]};
    }
`;

export const Content = styled.div`
    margin: auto 0;
    height: 100%;
`;
