import styled from "styled-components";

import base from "@/themes/base/base";

export const DataContainer = styled.div<{ isPartOfSharedRow?: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: white;
    position: relative;
    min-height: ${({ isPartOfSharedRow }) => (isPartOfSharedRow ? "0" : "20rem")};
    height: ${({ isPartOfSharedRow }) => (isPartOfSharedRow ? "15.1875rem" : "auto")};
`;

export const SectionOverlay = styled.div<OverlayStylingProps>`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    font-size: 1.5rem;
    font-weight: bold;
    border-right: ${(props) =>
        props.isQualifyingClient ? "none" : `2px solid ${base.colors.primary.orange[500]}`};
`;

export const LeftSection = styled(SectionOverlay)`
    flex: 0 0 17.5%;
`;

export const CenterTopSection = styled(SectionOverlay)`
    height: 50%;
    border-bottom: 2px solid ${base.colors.primary.orange[500]};
`;

export const CenterBottomSection = styled(SectionOverlay)`
    height: 50%;
`;

export const RightSection = styled(SectionOverlay)`
    flex: 0 0 17.5%;
    border-right: none;
`;

export const EyeTrackingImage = styled.img`
    width: 100%;
    height: auto;
`;

export const CenterSection = styled.div`
    flex: 0 0 65%;
    height: 100%;
`;

export const SectionText = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${base.colors.legacy.gray[50]};
`;

export const SectionPercentage = styled.div`
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 300;
    color: ${base.colors.legacy.gray[50]};
    text-align: center;
`;

interface OverlayStylingProps {
    isQualifyingClient?: boolean;
}

export const Overlay = styled.div<OverlayStylingProps>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: ${(props) => (props.isQualifyingClient ? "block" : "flex")};
    border: 2px solid ${base.colors.primary.orange[500]};
`;

export const ColoredOverlay = styled(Overlay)`
    background-color: ${base.colors.primary.orange[500]};
    opacity: 0.25;
`;

interface ContainerStylingProps {
    isPartOfSharedRow?: boolean;
}

export const Container = styled.div<ContainerStylingProps>`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    width: 100%;
    min-height: ${({ isPartOfSharedRow }) => (isPartOfSharedRow ? "21.1875rem" : "26.4375rem")};
    border-radius: 0.5rem;
    background: ${base.colors.neutral.grey[800]};
`;
