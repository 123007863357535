import { MapContainer as LeafletMapContainer } from "react-leaflet";

import styled from "styled-components";

import Controls from "@/pages/session-detail/components/cockpit-timeline/controls";

export const MapWithControlsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
`;

export const MapAndControlsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ExpandIconContainer = styled.div`
    cursor: pointer;
`;

export const MapWrapper = styled.div<{ renderedInModal: boolean }>`
    display: flex;
    flex-direction: column;
    height: ${({ renderedInModal }) => (renderedInModal ? "100%" : "17.563rem")};
`;

export const StyledMapContainer = styled(LeafletMapContainer)`
    height: 100%;
    width: 100%;
`;

export const SpeedControlContainer = styled.div`
    display: flex;
    width: 100%;
    height: 2rem;
    justify-content: center;
    align-items: center;
`;

export const SpeedControlInnerContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const SpeedBlock = styled.div`
    flex-grow: 1;
    background-color: ${(props) => props.color};
    width: 1rem;
    height: 1rem;
    margin-right: 0.125rem;

    &:first-child {
        margin-right: 0;
    }
`;

export const SpeedBlockLabel = styled.div`
    flex-grow: 1;
    color: #999;
    margin: 0 0.5rem;
    font-size: 14px;
    align-items: center;
    display: flex;
`;

interface StyledControlsProps {
    isInModal: boolean;
}

export const StyledControls = styled(Controls)<StyledControlsProps>`
    padding-top: ${({ isInModal }) => isInModal && "2rem"};
`;

export const TitleAndTooltipWrapper = styled.div`
    display: flex;
    gap: 0.25rem;
`;
