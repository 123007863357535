import "leaflet/dist/leaflet.css";

import { useModal } from "@/context/modal/modal";
import { useSessionDetailsContext } from "@/context/session-details/session-details";

import ContentLoader from "@/components/content-loader/content-loader";
import MapWithControls from "@/components/ui/map-with-controls/map-with-controls";

import { MapTimelineWrapper } from "./styles";

const MapTimeline = () => {
    const { openModal, setWidth, setHeight } = useModal();
    const { isFetchingMapData, mapTimelineData, isErrorMapData } = useSessionDetailsContext();

    if (isFetchingMapData) return <ContentLoader height="28.1875rem" />;

    const handleExpandContainerButtonClick = () => {
        setWidth("76.67%");
        setHeight("87.5%");
        openModal({
            content: (
                <MapWithControls
                    isInModal={true}
                    handleExpandContainerButtonClick={handleExpandContainerButtonClick}
                    mapData={mapTimelineData}
                    isLoading={isFetchingMapData}
                    isError={isErrorMapData}
                />
            ),
        });
    };

    return (
        <MapTimelineWrapper borderTopRadius={true} borderBottomRadius={true}>
            <MapWithControls
                isInModal={false}
                handleExpandContainerButtonClick={handleExpandContainerButtonClick}
                mapData={mapTimelineData}
                isLoading={isFetchingMapData}
                isError={isErrorMapData}
            />
        </MapTimelineWrapper>
    );
};

export default MapTimeline;
