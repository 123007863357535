import styled from "styled-components";

import FlyingCircuit from "@/pages/session-detail/components/flying-circuit-3d";
import TimelineChartFlight from "@/pages/session-detail/components/timeline-chart-flight";
import { PrimaryButton, TertiaryButton } from "@/pages/shared/shared.styles";

import base from "@/themes/base/base";

import Input from "@/components/ui/input";
import { Wrapper } from "@/components/ui/input/input.styles";

export const ModalContentWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const Title = styled.span`
    font-family: "Roboto";
    ${base.typography.extraLarge2Regular};

    color: ${base.colors.neutral.grey[50]};
    text-align: center;
`;

export const Description = styled.span`
    font-family: "Roboto";
    ${base.typography.baseRegular};

    color: ${base.colors.neutral.grey[50]};
    text-align: center;
    padding-top: 0.75rem;
`;

export const LogoutDescription = styled.span`
    font-family: "Roboto";
    ${base.typography.extraLargeMedium};

    color: ${base.colors.neutral.grey[50]};
    text-align: center;
`;

export const DeleteAccountDescription = styled.span`
    font-family: "Roboto";
    ${base.typography.extraLargeMedium};

    color: ${base.colors.neutral.grey[50]};
    text-align: center;
`;

export const ErrorDescription = styled.span`
    font-family: "Roboto";
    ${base.typography.baseRegular};

    color: ${base.colors.semantic.red[500]};
    text-align: center;
    padding-top: 0.75rem;
`;

export const Email = styled.span`
    font-family: "Roboto";
    ${base.typography.baseMedium};
`;

export const StyledEmail = styled.a`
    font-family: "Roboto";
    ${base.typography.extraLargeMedium};

    color: ${base.colors.primary.orange[500]};
    text-decoration: underline;
    text-decoration-thickness: from-font;

    &:hover {
        color: ${base.colors.primary.orange[300]};
    }

    &:visited {
        color: ${base.colors.primary.purple[500]};
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 1.5rem;
    width: 100%; //for the buttons to occupy 100% of width
`;

export const StyledConfirmButton = styled(PrimaryButton)``;

export const StyledCancelButton = styled(TertiaryButton)``;

export const StyledInput = styled(Input)`
    background-color: ${base.colors.neutral.grey[900]};
    border-radius: 0.125rem;

    ${Wrapper} {
        ${base.typography.smallRegular};

        background-color: ${base.colors.neutral.grey[900]};
        padding: 0.5rem 0.75rem;

        & input {
            ${base.typography.smallRegular};

            color: ${base.colors.neutral.grey[50]};
            margin-top: 0;
            height: 1.5rem;

            &::placeholder {
                color: ${base.colors.neutral.grey[500]};
            }
        }
    }
`;

export const AddUserErrorMessage = styled.span`
    font-family: "Roboto";
    ${base.typography.baseRegular};

    text-align: center;
    color: ${base.colors.semantic.red[500]};
    padding-top: 0.75rem;
`;

export const NotAddedUsersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 0.75rem;
`;

export const NotAddedUser = styled.span`
    font-family: "Roboto";
    ${base.typography.baseMedium};

    color: ${base.colors.semantic.red[500]};
`;

//Scoring component - CircuitScoring
export const RightContentWrapperDiv = styled.div`
    display: flex;
    position: relative;
`;

export const FlyingCircuitAndTimelineChartWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
`;

export const StyledFlyingCircuit = styled(FlyingCircuit)`
    width: 50%;
`;

export const StyledTimelineChartFlight = styled(TimelineChartFlight)`
    width: 50%;
`;
