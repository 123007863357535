import { useNavigate } from "react-router-dom";

import { useSessionDetailsContext } from "@/context/session-details/session-details";

import useMediaQuery from "@/utils/hooks/use-media-query";

import useGetNavigationItems from "./get-navigation-items";

import {
    Container,
    MenuItemContent,
    MenuContainer,
    ContentItem,
    Nav,
    Wrapper,
    MenuItemTitle,
    Indicator,
    Link,
    LogoWrapper,
    MenuItem,
    StyledLinkText,
    StyledHeatLogoForNavigation,
} from "./navigation.styles";

const Navigation = () => {
    const sections = useGetNavigationItems();
    const navigate = useNavigate();
    const { pauseMapTimeline } = useSessionDetailsContext();

    const isDesktopLarge = useMediaQuery("(min-width: 1920px)");

    const isActive = (path: string) => {
        return window.location.pathname.includes(path);
    };

    // Function to handle navigation
    const handleNavigation = (event: any, url: string) => {
        event.preventDefault();
        pauseMapTimeline();
        navigate(url);
    };

    return (
        <Wrapper>
            <Container>
                <Nav>
                    <LogoWrapper>
                        <StyledHeatLogoForNavigation />
                    </LogoWrapper>
                    <MenuContainer>
                        {sections.map((section: any) => (
                            <MenuItem key={section.code}>
                                <MenuItemTitle>{section.title}</MenuItemTitle>
                                <MenuItemContent>
                                    {section.navigationItems.map(
                                        ({ url, icon: Icon, title, code, enabled }: any) =>
                                            enabled && (
                                                <ContentItem
                                                    key={code}
                                                    onClick={(event) =>
                                                        handleNavigation(event, url)
                                                    }
                                                    href={url}
                                                    data-test-id={`navi-${title}`.toLowerCase()}
                                                >
                                                    <Indicator
                                                        isActive={isActive(url)}
                                                        className="indicator"
                                                    />
                                                    <Link className={isActive(url) ? "active" : ""}>
                                                        <Icon
                                                            width={isDesktopLarge ? 32 : 24}
                                                            height={isDesktopLarge ? 32 : 24}
                                                        />
                                                        <StyledLinkText>{title}</StyledLinkText>
                                                    </Link>
                                                </ContentItem>
                                            ),
                                    )}
                                </MenuItemContent>
                            </MenuItem>
                        ))}
                    </MenuContainer>
                </Nav>
            </Container>
        </Wrapper>
    );
};

export default Navigation;
