import styled from "styled-components";

import base from "@/themes/base/base";

export const MapTimelineWrapper = styled.div<{
    borderTopRadius: boolean;
    borderBottomRadius: boolean;
}>`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background-color: ${base.colors.neutral.grey[800]};
    justify-content: center;
    border-bottom-left-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
    border-bottom-right-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
    border-top-right-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};
    border-top-left-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};
    width: 100%;
    height: 100%;
`;
