import {
    SpeedBlock,
    SpeedBlockLabel,
    SpeedControlContainer,
    SpeedControlInnerContainer,
} from "../map-with-controls.styles";
import { speedBlockColors } from "../utils";

type SpeedControlProps = {
    displaySpeed: boolean;
};

const SpeedControl = (speedOptions: SpeedControlProps) => {
    return (
        <SpeedControlContainer>
            <SpeedControlInnerContainer>
                {speedOptions.displaySpeed && (
                    <>
                        <SpeedBlockLabel>Slower</SpeedBlockLabel>
                        {speedBlockColors.map((color: any, index: number) => (
                            <SpeedBlock key={index} color={color} />
                        ))}
                        <SpeedBlockLabel>Faster</SpeedBlockLabel>
                    </>
                )}
            </SpeedControlInnerContainer>
        </SpeedControlContainer>
    );
};

export default SpeedControl;
