import React from "react";

import FlightSlider from "./flight-slider";
import PlayPauseButton from "./play-pause-button";
import { ControlsContainer, ControlsInnerContainer, TimeDisplay } from "./styles";

interface ControlsProps {
    isDisabled: boolean;
    isPlaying: boolean;
    play: (doNotPlayIfPaused?: boolean) => void;
    pause: () => void;
    currentTick: number;
    setTick: (index: number) => void;
    totalTickCount: number;
    currentTime: string;
    duration: string;
}

const Controls: React.FC<ControlsProps> = ({
    isDisabled,
    isPlaying,
    currentTick,
    play,
    pause,
    totalTickCount,
    setTick,
    currentTime,
    duration,
    ...remainingProps
}) => {
    return (
        <ControlsContainer {...remainingProps}>
            <ControlsInnerContainer>
                <PlayPauseButton
                    isDisabled={isDisabled}
                    isPlaying={isPlaying}
                    togglePlayPause={() => (isPlaying ? pause() : play())}
                />
                <FlightSlider
                    isDisabled={isDisabled}
                    currentTime={currentTime}
                    currentTick={currentTick}
                    totalTickCount={totalTickCount}
                    onSliderChange={(newValue) => setTick(newValue)}
                    onPausePlayback={() => pause()}
                    onResumePlayback={() => play(true)}
                />
            </ControlsInnerContainer>
            <TimeDisplay isDisabled={isDisabled}>
                {currentTime} / {duration}
            </TimeDisplay>
        </ControlsContainer>
    );
};

export default Controls;
