// Function to calculate the current time in a timecode format based on the current index and step interval
export const calculateTime = (currentIndex: number, stepInterval: number) => {
    if (currentIndex < 0) {
        return "0:00";
    }

    const currentTimeInSeconds = (currentIndex * stepInterval) / 1000; // Convert milliseconds to seconds
    const currentMinutes = Math.floor(currentTimeInSeconds / 60); // Calculate minutes
    const currentSeconds = Math.floor(currentTimeInSeconds % 60); // Calculate remaining seconds
    const currentTime = `${currentMinutes}:${currentSeconds.toString().padStart(2, "0")}`; // Format time as MM:SS

    return currentTime;
};

// Helper function to round date to nearest second
export const roundDateToNearestSecond = (dateString: string) => {
    const date = new Date(dateString);

    return new Date(Math.round(date.getTime() / 1000) * 1000);
};

// Function to get the last observation for each timestamp
export const flattenObservations = (sortedData: any) => {
    return sortedData.reduce((acc: any, dataPoint: any) => {
        const roundedDate = roundDateToNearestSecond(dataPoint.capturedDate);
        acc[roundedDate.toISOString()] = dataPoint;

        return acc;
    }, {});
};

// Function to get either the minimum or maximum DateTime from all instruments
export const getMinMaxDateTime = (flattenedChartData: any, getMin: boolean) => {
    return Object.values(flattenedChartData).reduce(
        (resultDateTime: Date | null, instrumentData: any) => {
            const instrumentDataArray = Object.values(instrumentData).flat(); // Flatten the data points

            return instrumentDataArray.reduce(
                (currentResultDateTime: Date | null, dataPoint: any) => {
                    const dataPointDateTime = roundDateToNearestSecond(dataPoint.capturedDate); // Convert to Date object

                    if (!currentResultDateTime) return dataPointDateTime; // Initial case

                    // Return either the min or max DateTime
                    return getMin
                        ? dataPointDateTime < currentResultDateTime
                            ? dataPointDateTime
                            : currentResultDateTime
                        : dataPointDateTime > currentResultDateTime
                          ? dataPointDateTime
                          : currentResultDateTime;
                },
                resultDateTime,
            );
        },
        null,
    );
};
